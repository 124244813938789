$black: #191919;
$green: #70bf53;


$pad: 15px;
$mar: 15px;
$white: #FFFFFF;
$blue: #304e8c;
$grey: #707070;
$greylight: #dfdfdf;
$lightblue: #4e6eb1;
$bluergb: rgb(48,78,139);


$font: Helvetica, Arial, sans-serif;
